/* FIX FOR IE BROWSERS */
@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  header .header > a {
   /* min-width: 100%;*/
  }
  header .header > a:first-of-type {
    min-width: 300px;
  }
  .landing-page-content {

  }

  /* FIXING MAIN GRID */
  main > div {
    display: -ms-grid;
    margin-top: 1rem;
  }

  /* LOGIN WINDOW */
  main > div > div > div {
    max-width: 58rem;
  }

  /* REMOVING GRID FOR VEHICLE DETAILS AND USING FLEX */
  .details form > div:first-of-type,
  .details > section > div:last-of-type {
    display: -ms-flexbox;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
  }

  .details form > div .form-group:nth-of-type(odd),
  .details form > div > label:nth-of-type(odd),
  .details > section > div:last-of-type .form-group:nth-of-type(odd) {
    min-width: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    margin-right: 1rem;
  }
  .details form > div .form-group:nth-of-type(even),
  .details form > div > label:nth-of-type(even),
  .details > section > div:last-of-type .form-group:nth-of-type(even) {
    min-width: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    margin-left: 1rem;
  }
}

@media all and (-ms-high-contrast: none) and (min-width: 82rem),
  (-ms-high-contrast: active) and (max-width: 82rem) {
  /* MAIN GRID */
  main > div {
    -ms-grid-columns: minmax(min-content, 1fr) minmax(min-content, 2fr) minmax(min-content, 1fr)
      minmax(min-content, 1fr);
    -ms-grid-rows: auto;
    width: 82rem;
    margin: 1rem auto;
  }

  main > div > section {
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  main > div > aside:first-of-type {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  main > div > aside:last-of-type {
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
  }

  /*
  main .two-colums {
    display:-ms-grid;
    -ms-grid-column-span: 2;
    width: 100%;

    display: flex;
    flex-direction: row;
  } */

  /* WEIRD WORKAROUND FOR IE11 */
  main .two-colums > div {

    float: left;
    width: 50%;
    /*flex: 50%;*/

  }
  main .two-colums:after {
    content: "";
    display: table;
    clear: both;
  }

  main .registration-form-content-contact, main .registration-form-content-vehicle {
    display:block;
  }

  main .registration-form-content-vehicle .button-group {
    margin-top: 2rem;
  }

  /*
  main .registration-form-content-novehicle button {
    margin-top: 1rem;
  }
  */
/*
  main .two-colums > div:nth-child(2n) {
    -ms-grid-column: 2
  }

  main .two-colums > div:nth-child(2n+1) {
    -ms-grid-column: 1;
  }

  main .two-colums > div:nth-child(1), main .two-colums > div:nth-child(2) {
    -ms-grid-row: 1
  }

  main .two-colums > div:nth-child(3), main .two-colums > div:nth-child(4) {
    -ms-grid-row: 2
  }
  main .two-colums > div:nth-child(5), main .two-colums > div:nth-child(6) {
    -ms-grid-row: 3
  }
  main .two-colums > div:nth-child(7), main .two-colums > div:nth-child(8) {
    -ms-grid-row: 4
  }
  main .two-colums > div:nth-child(9), main .two-colums > div:nth-child(10) {
    -ms-grid-row: 5
  }
  main .two-colums > div:nth-child(11), main .two-colums > div:nth-child(12) {
    -ms-grid-row: 6
  }

  main .two-colums > div:nth-child(13), main .two-colums > div:nth-child(14) {
    -ms-grid-row: 7
  }

  main .two-colums > div:nth-child(15), main .two-colums > div:nth-child(16) {
    -ms-grid-row: 8
  }

  main .two-colums > div:nth-child(17), main .two-colums > div:nth-child(18) {
    -ms-grid-row: 9
  }
*/
}

@media all and (-ms-high-contrast: none) and (max-width: 82rem),
  (-ms-high-contrast: active) and (max-width: 82rem) {
  /* MAIN GRID */
  main > div {
    -ms-grid-columns: minmax(min-content, 1fr) minmax(min-content, 1fr);
    -ms-grid-rows: auto;
    max-width: 42rem;
    width: 90%;
    margin: 1rem auto;
  }

  main > div > section {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  main > div > aside {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    margin-top: 2rem;
  }

  main > div > aside:first-of-type {
    -ms-grid-row: 2;
  }

  main > div > aside:last-of-type {
    -ms-grid-row: 3;
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 48rem),
  (-ms-high-contrast: active) and (max-width: 48rem) {
  /* MAIN GRID */
  main > div {
    -ms-grid-columns: minmax(min-content, 1fr);
    -ms-grid-rows: auto;
    width: 90%;
    margin: 1rem auto;
  }

  main > div > section:not(.landing-page-content) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  main > div > aside {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    margin-top: 2rem;
  }

  main > div > section.landing-page-content > h2+h3+section+div > div {
    flex: none;
    -ms-flex: none;

  }

  main > div > aside:first-of-type {
    -ms-grid-row: 2;
  }

  main > div > aside:last-of-type {
    -ms-grid-row: 3;
  }

  /* SECTIONS, HEADER AND FOOTERS */

  #root main > div > section:not(.landing-page-content) > section {
    padding-bottom: 3rem;
  }

  #root main .container header > footer,
  #root main > div > section > section > footer {
    margin-top: 2rem;
  }

  /* VEHICLE DETAILS, FORM GROUPS */
  #root main .details form > div .form-group,
  #root main .details form > div > label,
  #root main .details > section > div:last-of-type .form-group {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

/* FIX FOR IE AND EDGE BROWSERS */
_:-ms-lang(x),

/* HTML5 SPECIFIC ELEMENTS */
#root main aside > section details {
  display: block;
}

/* FIX FOR INVALID HEX COLORS */
#root .danger {
  background: rgba(208, 2, 27, 0.125);
}

main > div > div {
  background: rgba(0, 0, 0, 0.133);
}
